<template>
  <div v-if="indexForms" class="mainDiv">
    <form v-if="indexForms.length" @submit.prevent="saveIndex">
      <div class="md-layout">
        <div class="md-layout-item">
          <VasionDroplist
            v-slot="slotItem"
            v-model="selectedAttributeForm"
            :dataArray="field.dropdownValues"
            :title="`${$formsLabel}`"
            type="plain-list"
            valueName="value"
            displayName="name"
            @input="newReportTypeSelection, setNameFromId"
          >
            {{ slotItem.item.name }}
          </VasionDroplist>
        </div>
        <div class="md-layout-item">
          <md-field md-inline>
            <label>Config Name</label>
            <md-input id="configName" v-model="configName" name="configName" />
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-checkbox v-model="barcodeAsFileName">
            Barcode Value As File Name
          </md-checkbox>
        </div>
        <div v-if="barcodeAsFileName" class="md-layout-item">
          <md-field md-inline>
            <label>Page Number</label>
            <md-input id="configName" v-model="pageNumber" name="configName" />
          </md-field>
        </div>
        <div v-if="barcodeAsFileName" class="md-layout-item">
          <md-field md-inline>
            <label>Barcode Number</label>
            <md-input id="configName" v-model="barcodeNumber" name="configName" />
          </md-field>
        </div>
      </div>
      <md-table v-model="emptyIndexFields" md-card>
        <md-table-row slot="md-table-row" slot-scope="{ slotItem }">
          <md-table-cell md-label="Index Field">
            {{ slotItem.IndexFieldTitle }}
          </md-table-cell>
          <md-table-cell md-label="Page Number">
            <md-field>
              <md-input v-model="slotItem.PageNumber" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Barcode Number">
            <md-field>
              <md-input v-model="slotItem.BarcodeNumber" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="From">
            <md-field>
              <md-input v-model="slotItem.From" type="number" />
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="To">
            <md-field>
              <md-input v-model="slotItem.To" type="number" />
            </md-field>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="rightAlign">
        <md-button type="submit" class="md-dense md-raised md-primary mt-4">
          Save
        </md-button>
        <md-button id="btnCancelBarcodeConfig" class="md-dense md-raised md-default mt-4" @click="closeWindow">
          Cancel
        </md-button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: 'NewBarcodeIndexingEditor',
  props: {
    isNew: {
      type: Boolean,
      default: true,
      required: true,
    },
    item: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: function () {
    return {
      attributeFormValueIndex: 1,
      barcodeAsFileName: false,
      barcodeNumber: '',
      configName: '',
      indexFormName: '',
      pageNumber: '',
      selected: [],
      selectedAttributeForm: 1,
      selectedAttributeFormArray: [],
    }
  },
  computed: {
    allBarcodeIndexFields() { return this.$store.state.mainViews.allBarcodeIndex },
    barcodeGridData() { return this.$store.state.mainViews.barcodeGridData },
    emptyIndexFields() { return this.$store.state.mainViews.emptyIndexFields },
    indexForms() { return this.$store.state.attributeForm.forms },
  },
  watch: {
    selectedAttributeForm: function () {
      const formName = this.indexForms.find((form) => form.value === this.selectedAttributeForm).name
      this.indexFormName = formName

      this.attributeFormValueIndex = this.selectedAttributeForm
      this.$store.dispatch('mainViews/getIndexFields', this.selectedAttributeForm)
    },
  },
  created() {
    this.$store.dispatch('mainViews/getAllBarcodeIndex')
    this.$store.dispatch('mainViews/getIndexFields', 1) // TODO: This is not going to work long term - there may not be a "1" attribute form ID
  },
  methods: {
    closeWindow() {
      this.$emit('closeWindow')
    },
    onSelect(items) {
      this.selected = items
    },
    saveIndex() {
      const returnObject = {
        ID: 0,
        IndexFormID: this.selectedAttributeForm,
        IndexFormName: this.indexFormName,
        ConfigName: this.configName,
        BarcodeValueAsFileName: this.barcodeAsFileName,
        FileNamePageNumber: this.pageNumber,
        FileNameBarcodeNumber: this.barcodeNumber,
        Fields: this.emptyIndexFields,
      }
      this.$store.dispatch('mainViews/saveBarcodeIndex', returnObject)
      this.closeWindow()
    },
  },
};
</script>

<style lang="scss" scoped>
  .mainDiv {
    margin: 10px 10px 10px 10px;
    overflow: auto;
  }

  .rightAlign {
    text-align: right;
  }
</style>
