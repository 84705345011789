<template>
  <div v-if="apiToken && barcodeListData" class="mainDiv">
    <div class="innerDiv">
      <md-button id="btnCreateVault" class="md-raised md-default" @click="toggleNewDialog()">
        <div class="md-layout">
          <div class="vasion-menuIcon">
            <VasionAddIcon />
          </div>
          <div class="vasion-iconButtonText">
            New Barcode Values
          </div>
        </div>
      </md-button>
      <div class="gridDiv vasion-html-table-default">
        <table>
          <thead>
            <tr>
              <th>
                <label class="vasion-html-table-header">Name</label>
              </th>
              <th>
                <label class="vasion-html-table-header">{{ $formsLabel }} Name</label>
              </th>
              <th>
                <label class="vasion-html-table-header">Controls</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(barcode, index) in barcodeListData"
              :key="index"
              :class="{'whiteBackground': index % 2 === 0}"
            >
              <td>
                <label class="vasion-html-table-field">{{ barcode.ConfigName }}</label>
              </td>
              <td>
                <label class="vasion-html-table-field">{{ barcode.IndexFormName }}</label>
              </td>
              <td>
                <div class="md-layout">
                  <div>
                    <md-button class="md-icon-button md-dense" title="Edit Barcode Indexing" @click="toggleEditDialog(barcode.ID)">
                      <VasionEditIcon />
                    </md-button>
                  </div>
                  <div>
                    <md-button class="md-icon-button md-dense" title="Remove Barcode Indexing" @click="deleteItem(barcode.ID)">
                      <VasionDeleteIcon />
                    </md-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <md-dialog id="create-new-field" :md-active.sync="showEditDialog">
        <ExistingBarcodeIndexingEditor :itemId="selectedBarcodeID" @closeWindow="toggleEditDialog(0)" />
      </md-dialog>
      <md-dialog id="create-new-field" :md-active.sync="showNewDialog">
        <NewBarcodeIndexingEditor @closeWindow="toggleNewDialog()" />
      </md-dialog>
    </div>
  </div>
</template>

<script>
import ExistingBarcodeIndexingEditor from '@/components/barcodes/ExistingBarcodeIndexingEditor.vue'
import NewBarcodeIndexingEditor from '@/components/barcodes/NewBarcodeIndexingEditor.vue'

export default {
  name: 'TheBarcodeIndexingConfig',
  components: {
    ExistingBarcodeIndexingEditor,
    NewBarcodeIndexingEditor,
  },
  data: function () {
    return {
      selectedBarcodeID: 0,
      showEditDialog: false,
      showNewDialog: false,
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    barcodeGridData() { return this.$store.state.mainViews.barcodeGridData },
    barcodeListData() {
      if (this.$store.state.mainViews.allBarcodeIndex && this.$store.state.mainViews.allBarcodeIndex.BIList) {
        return this.$store.state.mainViews.allBarcodeIndex.BIList
      }

      return {}
    },
  },
  created() {
    this.$store.dispatch('mainViews/getAllBarcodeIndex')
    this.$store.dispatch('attributeForm/getForms')
  },
  methods: {
    deleteItem(barcodeIndexingID) {
      const payload = {
        Value: barcodeIndexingID,
      }
      this.$store.dispatch('mainViews/deleteBarcodeIndex', payload)
    },
    toggleEditDialog(barcodeID) {
      this.selectedBarcodeID = barcodeID
      this.showEditDialog = !this.showEditDialog
    },
    toggleNewDialog() { this.showNewDialog = !this.showNewDialog },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .mainDiv {
    margin-left: 10px;
    margin-right: 10px;
  }

  .gridDiv {
    height: calc(100vh - 140px);
    overflow: auto;
  }

  .whiteBackground {
    background-color: $white;
  }

  #create-new-field {
    width: 1160px;
    height: 750px;
  }
</style>
